<template>
    <div class="page-body select-company" style="height: 100vh">
        <NoButtonModal :open="true" id="company_dropdown_modal">
            <div class="modal-content-header bottom-20">
                <span style="font-size: 20px; font-weight: bold; margin-left: 20px">Select Company</span>
            </div>
            <div class="mb-4 d-flex justify-content-center w-100 mt-5">
                <label for="company" class="add-new-app-label w-20 my-2">Company List</label>
                <Multiselect
                    id="company"
                    track-by="id"
                    label="company_name"
                    class="position-relative w-60"
                    placeholder="Search Company..."
                    v-model="companyData"
                    :options="companyList"
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                    @change="handleCompanyChange($event)"
                    :disabled="loading"
                />
            </div>
            <div class="align-right" style="bottom: 10%; position: absolute; right: 5%">
                <button
                    :class="`margin-0 procced-button`"
                    :disabled="!companyData.id"
                    @click="submit"
                    :style="{ cursor: !companyData.id ? 'not-allowed' : '' }"
                >
                    Proceed to Dashboard
                    <span class="material-icons-outlined" style="color: white" v-if="!loading">arrow_forward</span>
                    <Loading
                        :outerHeight="'21px'"
                        :outerWidth="'21px'"
                        :height="'21px'"
                        :width="'21px'"
                        :color="'white transparent transparent transparent'"
                        :borderWidth="'3px'"
                        v-else
                    />
                </button>
            </div>
        </NoButtonModal>
    </div>
</template>

<script>
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import Loading from '@/components/general/loading/loading';
    import { companies, auth, users } from '@/util/apiRequests';
    import { getSeniors, getProfile } from '../../util/graphql/queries';
    import { cognitoSignIn } from '../../util/cognitoAuthUtil';
    import { API } from 'aws-amplify';

    export default {
        name: 'SelectCompany',
        data() {
            return {
                email: this.$route.params.email,
                password: this.$route.params.password,
                companyList: [],
                loading: false,
                companyData: '',
            };
        },
        components: { NoButtonModal, Loading },
        methods: {
            handleCompanyChange(event) {
                this.companyList.forEach((company) => {
                    if (company.company_name == event.target.value) {
                        this.companyData = company;
                    }
                });
            },
            async getLoggedInUserDetail(userId) {
                try {
                    const res = await this.$api.get(users.getUserDetails(userId));
                    return { user_detail: res.data };
                } catch (e) {
                    console.error(e);
                }
            },
            async submit() {
                this.loading = true;

                const res = await this.$api.post(auth.signIn(), {
                    email: this.email,
                    password: this.password,
                    host: window.location.host,
                    companyId: this.companyData.id,
                });

                // note that errors for this are caught in the api file, in the interceptor
                if (res?.status === 200) {
                    const session = await cognitoSignIn(this.email, this.password);
                    const {
                        data: { getSeniors: membersList },
                    } = await API.graphql({ query: getSeniors });
                    const {
                        data: { getProfile: profileDetails },
                    } = await API.graphql({ query: getProfile });
                    localStorage.setItem('sessionContactId', session.username);
                    if (profileDetails.families && profileDetails.families[0]) {
                        localStorage.setItem('familyId', profileDetails.families[0].familyId);
                    }
                    await this.$store.commit('persistRelatedClients', membersList);
                    let loggedInUserDetail = await this.getLoggedInUserDetail(res.data.user.id);
                    let user = { ...res.data.user, ...loggedInUserDetail };
                    this.$store.commit('persistUser', user);
                    this.$store.commit('persistBuildId', res.data.buildId);
                    await this.$router.replace({ name: 'Dashboard', replace: true });
                } else {
                    console.log('Error: ', res);
                }

                this.loading = false;
            },
        },
        async created() {
            if (!this.email || !this.password) {
                this.$toasted.error('You must be signed in to view that page.');
                return this.$router.replace({ name: 'SignIn', replace: true });
            }
            try {
                // const endpoint = companies.getAllCompanies(1);
                const endpoint = companies.getAllCompanies();
                const response = await this.$api.get(endpoint);
                // const response = await this.$api.post(endpoint, {
                //     email: this.email,
                //     password: this.password,
                //     host: window.location.host,
                // });
                this.companyList = response.data.info;
                this.$store.commit('setDefaultCompanyId', response.data.info[0].id);
            } catch (err) {
                console.error(err);
            }
        },
    };
</script>

<style scoped>
    .select-company{
        background-image: url('../../assets/px/landscape-morning-fog-mountains-with-hot-air-balloons-sunrise.png') !important;
    }
    .modal-content-header {
        background-color: #f1f7ff;
        padding: 10px;
        display: flex;
        align-items: center;
    }
    .procced-button {
        box-shadow: inset 0 0 0 1px var(--button-color);
        background-color: var(--button-color) !important;
        color: white !important;
        border: none;
        border-radius: 5px;
        padding: 9.5px 20px;
        gap: 10px;
        display: flex;
    }
    .disabled {
        cursor: not-allowed;
    }

    /* ### Custom scroll ### */

    /* width */
    ::-webkit-scrollbar {
        width: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #ccc3c3;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: var(--base-theme-color);
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: var(--base-theme-color);
    }
</style>

<style>
    .select-company .modal__content {
        padding: 0;
        border-radius: 10px;
        height: 50vh;
    }
</style>
